.app__navbar {
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    background: var(--color-white);
    box-shadow: 5px 10px 8px #888888;
    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50px;
}

.app__navbar-logo img {
    width: 150px;
}


.app__navbar-title {
    display: flex;
    font-family: 'Helvetica';
    font-size: 2rem;
    color: var(--color-darkpink);
    font-weight: 900;
    padding: 10px ;
    justify-content:space-evenly ;
    align-items: center;
    
}

.app__navbar-smallscreen {
    display: none;
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 50px;
    }
}

@media screen and (max-width: 1150px) {

    .app__navbar-smallscreen {
        display: flex;
    }
    
    .app__navbar-logo img {
        width: 50px;
    }
}

@media screen and (max-width: 300px) {
    .app__navbar {
        padding: 1rem;
        flex-direction: column;
    }

    .app__navbar-logo img {
        width: 50px;
    }
}