.app__ratingbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.app__ratingbar-title {
    display: flex;
    font-family: 'Helvetica';
    text-align: center;
    font-size: 1.5rem;
    color: var(--color-black);
    font-weight: 600;
    padding: 20px ;
    justify-content:space-evenly ;
    align-items: center;
}

.app__ratingbar-label {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
    text-align: center;
    align-items: center;
    padding: 10px;
}