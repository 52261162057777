.section__padding {
  padding: 4rem 6rem;
}

.app {
    background-color: #ffffff ;
}

.main {
  padding: 5px;
  flex-direction: column;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
}
.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }
}
